import React, { useEffect, useMemo } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorPage from "./pages/errorPage";
import Otp from "./pages/otp/";
import SuccessPage from "./pages/successPage";
import Verify from "./pages/verify";
import Pin from "./pages/pin";
import SuccessPayment from "./pages/paymentSuccess";
import PaymentFailed from "./pages/paymentFailed"
const Router = (props) => {
  
    const router = [
      // { path: `/virtualweek-registration/`, exact: true, component: Register },
      {
        path: `/otp`,
        exact: true,
        component: Otp,
      },
    ];
  

  
    return (
          <BrowserRouter>
            <Routes>
              {/* {router.map((router, i) => {
                // if (access == "givenAccessVW3") {
                return <Route {...router} key={i} />;
                // } else {
                //   return <ProtectedRoute {...router} key={i} />;
                // }
              })} */}
                <Route path="/otp" element={<Otp/>} />;
                <Route path="/verify/:token" element={<Verify/>} />;
                <Route path="/500" element={<ErrorPage/>}/>;
                <Route path="/success" element={<SuccessPage/>}/>;
                <Route path="/payment" element={<Pin/>}/>;
                <Route path="/payment-success" element={<SuccessPayment/>}/>;
                <Route path="/payment-failed" element={<PaymentFailed/>}/>
            </Routes>
          </BrowserRouter>
    );
  };
  
  export default Router;