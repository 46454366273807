import React, { useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function Verify() {
  const { token } = useParams();
  const history = useNavigate();

  useEffect(() => {
    //add to localstorage
    if (token == 0 || token == null) {
        Cookies.remove("token")
      } else {
        Cookies.set("token", token);;
      }
      history("/otp");
    }, []);
    return <>{token}</>;
}
