import React, { useEffect } from "react";
import './styles.css';

export default function ErrorPage() {
  
    return (
        <div className="App-wrapper">
            <div className="content">
                <div className="inner-circle"><img src="/error.jpg" alt="image" width="500px" height="300px" /></div>
                <h2 className="inner-status">Woops!</h2>
                <h2 className="inner-status">Kredential Token Sudah</h2>
                <h2 className="inner-status">Berakhir atau bermasalah.</h2>
                <h2 className="inner-status">Silahkan ulangi kembali :(</h2>
            </div>
        </div>
    )
}
