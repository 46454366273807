/* eslint-disable no-restricted-globals */
import React, { useEffect,useState } from "react";
import './styles.css';

export default function PaymentSuccess() {

    const [seconds, setSeconds] = useState(5);
    // eslint-disable-next-line no-restricted-globals
    const query = new URLSearchParams(location.search)
    const redirect = query.get("redirect")
    useEffect(() => {
        const interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleBack = (val) => {
        if (val === 0) {
            window.location.href = `${redirect}`
        }
    }

  
    return (
    <>
        <div className="success-wrapper" style={{marginTop:"5%"}}>
            <div className="content">
                <div className="duwit-logo">
                    <img src="/DuwitLogo2.png" width={190} height={95} alt="duwit-logo"/>
                </div>
                <div className="text-below-icon">
                    <img src="/check.svg" alt="check-icon" width={240}/>
                </div>
                <h5 style={{marginTop:"-36px",marginBottom:"24px"}}>Pembayaran berhasil</h5>
            </div>

        </div>
        {redirect ? 
            <div className="content" style={{height:"180px",backgroundColor: "#4682B4"}}>
                <div className="back-wrapper" style={{paddingTop:"40px"}}>
                    <h5 style={{color:"white"}}>Mengarahkan kembali dalam {seconds === 0 ? handleBack(seconds) : seconds} detik atau:</h5>
                    <button className="btn" style={{border:"1px solid white", color:"white", width:"240px", marginTop:"20px"}} type="button" onClick={()=>{history.back()}}>Klik disini untuk kembali</button>
                </div>
            </div> : null
        }

    </>
    )
}
