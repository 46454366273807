import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import './styles.css';
import axios from "axios"
import {Api} from "../../utils/constant"
import Cookies from "js-cookie";
import { useParams, useNavigate } from "react-router-dom";

function Otp() {
  const [otpValue, setOtpValue] = useState("") 
  const [data, setData] = useState([])
  const [validate, setValidate] = useState([])
  const [error, setError] = useState("")
  const token = Cookies.get("token")
  const history = useNavigate();
  var md5 = require('md5');
  useEffect(()=>{
    getMsisdnData()
  },[])

  const getMsisdnData = async () => {
    return await axios({
      url: `${process.env.REACT_APP_API_WALLET_URL}/v1${Api.reqOtp}?token=${token}`,
      method: "get",
      headers: {
        Authorization: `Basic dXNlcm5hbWU6cGFzc3dvcmQ=`
      },
    }).then((res) => {
        setData(res.data)
        console.log(res.data); 
      }).catch((err) => {
        if (err.response.data.error_code === "INVALID_TOKEN") {
          history("/500")
          
        }
      })
  }

  const validateOtp = async (otp,token) => {
    const config = {
        method: "get",
        url: `${process.env.REACT_APP_API_WALLET_URL}/v1${Api.validateOtp}`,
        headers: {
            Authorization: `Basic dXNlcm5hbWU6cGFzc3dvcmQ=`
        },
        params: {
            otp: otp,
            token : token ,
        },
    }
    console.log(config);
    return await axios(config).then((res) => {
        setValidate(res.data)
        window.location.href = res.data.acc_link_success_rdr_url 
        // history("/success")
      }).catch((err) => {
        
        setError(err.response.data.message)
        if (err.response.data.error_code === "EXPIRED_OTP") {
          // history("/500")
          console.log(err.response.data.status_code)
        }
        else if (err.response.data.error_code === "ACCOUNT_ALREADY_EXISTS") {
          history("/success")
        }
    })
  }
  const handleChangeOtp = (val) => {
    setOtpValue(val);
    if (val.length === 4) {
        let md5Otp = md5(val)
        validateOtp(md5Otp,token);
    } else {
        setError()
    }
  }

  return (
    <div className="App">
    <div className="card card-outline card-primary">

      <div className="card-body">
        <p className="login-box-msg p-medium">
          Klik “Minta OTP” dibawah ini dan tekan <strong>Kirim</strong> dari Whatsapp Anda untuk mendapatkan kode OTP. Masukkan balasan dari Whatsapp ke kotak OTP dibawah ini.
        </p>

        <div className="text-center mt-2 mb-3">
          <a href={data.wa_link} target="_blank" className="btn btn-block btn-primary">
            Minta OTP
          </a>
        </div>
        
        <div className="text-center mt-2 mb-3">
          <p className="login-box-msg p-small">
            Masukkan kode OTP yang kami kirim ke
          </p>
          <p>
            <strong>{data?.msisdn ? data.msisdn?.substring(0,5)+"****"+data.msisdn?.substring(9,13) : null}</strong>
          </p>
        </div>
        {error ? <p style={{color:"red"}}>{error}</p>:null}
        <div className="otp-wrapper">
          <OtpInput
              numInputs={4}
              inputStyle={{
                width: 50,
                height: 50,
              }}
              containerStyle={{gap:`8px`}}
              isInputSecure={true}
              onChange={(val) => handleChangeOtp(val)}
              value={otpValue}
              isInputNum={true}
            />
        </div>



          {/* <input className="otp" type="text" onInput={digitValidate(this)} onKeyUp={tabChange(1)} maxLength={1} />
          <input className="otp" type="text" onInput={digitValidate(this)} onKeyUp={tabChange(2)} maxLength={1} />
          <input className="otp" type="text" onInput={digitValidate(this)} onKeyUp={tabChange(3)} maxLength={1} />
          <input className="otp" type="text" onInput={digitValidate(this)} onKeyUp={tabChange(4)} maxLength={1} /> */}
          {/* <!-- <input className="otp" type="text" oninput='digitValidate(this)' onkeyup='tabChange(5)' maxlength=1 >
          <input className="otp" type="text" oninput='digitValidate(this)'onkeyup='tabChange(6)' maxlength=1 > --> */}

          {/* <!-- <div className="input-group mb-3">
            <input type="email" className="form-control" placeholder="Email">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="password" className="form-control" placeholder="Password">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <div className="icheck-primary">
                <input type="checkbox" id="remember">
                <label for="remember">
                  Remember Me
                </label>
              </div>
            </div>
            
            <div className="col-4">
              <button type="submit" className="btn btn-primary btn-block">Sign In</button>
            </div> --> 
            
          </div>*/}
        

        {/* <p className="mb-1">
          <a href="forgot-password.html">I forgot my password</a>
        </p>
        <p className="mb-0">
          <a href="register.html" className="text-center">Register a new membership</a>
        </p> */}
      </div>
      {/* <!-- /.card-body --> */}
    </div>
    {/* <!-- /.card --> */}

 
    </div>
  );
}

export default Otp;
