// import logo from './logo.svg';
// import './App.css';
// import React, { useEffect, useState } from "react";
// import OtpInput from "react-otp-input";

// function App() {
//   const [otpValue, setOtpValue] = useState("") 
 
//   const handleChangeOtp = (val) => {
//     setOtpValue(val);
//   }
//   // let digitValidate = function(ele){
//   //   console.log(ele.value);
//   //   ele.value = ele.value.replace(/[^0-9]/g,'');
//   // }

//   // let tabChange = function(val){
//   //     let ele = document.querySelectorAll('input');
//   //     if(ele[val-1].value !== ''){
//   //       ele[val].focus()
//   //     }else if(ele[val-1].value === ''){
//   //       ele[val-2].focus()
//   //     }   
//   // }
//   return (
//     <div className="App">
//       {/* <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>

//       </header> */}
//     {/* <!-- /.login-logo --> */}
//     <div className="card card-outline card-primary">
//       {/* <!-- <div className="card-header text-center">
//         <a href="../../index2.html" className="h1"><b>Admin</b>LTE</a>
//       </div> --> */}
//       <div className="card-body">
//         <p className="login-box-msg p-medium">
//           Klik “Minta OTP” dibawah ini dan tekan <strong>Kirim</strong> dari Whatsapp Anda untuk mendapatkan kode OTP. Masukkan balasan dari Whatsapp ke kotak OTP dibawah ini.
//         </p>

//         <div className="text-center mt-2 mb-3">
//           <a href="#" className="btn btn-block btn-primary">
//             Minta OTP
//           </a>
//         </div>
        
//         <div className="text-center mt-2 mb-3">
//           <p className="login-box-msg p-small">
//             Masukkan kode OTP yang kami kirim ke
//           </p>
//           <p>
//             <strong>+6281****9282</strong>
//           </p>
//         </div>
        
//         <div className="otp-wrapper">
//           <OtpInput
//               numInputs={4}
//               inputStyle={{
//                 width: 50,
//                 height: 50,
//               }}
//               containerStyle={{gap:`8px`}}
//               isInputSecure={true}
//               onChange={(val) => handleChangeOtp(val)}
//               value={otpValue}
//             />
//         </div>

//           {/* <input className="otp" type="text" onInput={digitValidate(this)} onKeyUp={tabChange(1)} maxLength={1} />
//           <input className="otp" type="text" onInput={digitValidate(this)} onKeyUp={tabChange(2)} maxLength={1} />
//           <input className="otp" type="text" onInput={digitValidate(this)} onKeyUp={tabChange(3)} maxLength={1} />
//           <input className="otp" type="text" onInput={digitValidate(this)} onKeyUp={tabChange(4)} maxLength={1} /> */}
//           {/* <!-- <input className="otp" type="text" oninput='digitValidate(this)' onkeyup='tabChange(5)' maxlength=1 >
//           <input className="otp" type="text" oninput='digitValidate(this)'onkeyup='tabChange(6)' maxlength=1 > --> */}

//           {/* <!-- <div className="input-group mb-3">
//             <input type="email" className="form-control" placeholder="Email">
//             <div className="input-group-append">
//               <div className="input-group-text">
//                 <span className="fas fa-envelope"></span>
//               </div>
//             </div>
//           </div>
//           <div className="input-group mb-3">
//             <input type="password" className="form-control" placeholder="Password">
//             <div className="input-group-append">
//               <div className="input-group-text">
//                 <span className="fas fa-lock"></span>
//               </div>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-8">
//               <div className="icheck-primary">
//                 <input type="checkbox" id="remember">
//                 <label for="remember">
//                   Remember Me
//                 </label>
//               </div>
//             </div>
            
//             <div className="col-4">
//               <button type="submit" className="btn btn-primary btn-block">Sign In</button>
//             </div> --> 
            
//           </div>*/}
        

//         {/* <p className="mb-1">
//           <a href="forgot-password.html">I forgot my password</a>
//         </p>
//         <p className="mb-0">
//           <a href="register.html" className="text-center">Register a new membership</a>
//         </p> */}
//       </div>
//       {/* <!-- /.card-body --> */}
//     </div>
//     {/* <!-- /.card --> */}

 
//     </div>
//   );
// }

// export default App;

import Router from "./router";

const App = () => <Router />;

export default App;
