import React, { useEffect } from "react";
import "../successPage/styles.css";

export default function SuccessPage() {
  
    return (
        <div className="App-wrapper">
            <div className="content">
                <div className="inner-circle"><img src="/connected.jpg" alt="image" width="500px" height="300px" /></div>
                <h2 className="inner-status">Akun Anda berhasil terhubung</h2>
            </div>
        </div>
    )
}
