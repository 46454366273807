/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import './styles.css';
import axios from "axios"
import {Api} from "../../utils/constant"
import Cookies from "js-cookie";
import { useParams, useNavigate } from "react-router-dom";

function Pin() {
  const [pinValue, setPinValue]= useState("")
  const [transactionPay, setTransactionPay] = useState([])
  const [payment, setPayment] = useState([])
  const [error, setError] = useState(false)
  const [isDisable, setIsDisable] = useState(true)
  const query = new URLSearchParams(location.search)
  const token = query.get("token")
  const history = useNavigate();
  var md5 = require('md5');
  
  
  const validatePin = async (pin,token) => {
    const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_WALLET_URL}/v1${Api.payment}`,
        headers: {
            Authorization: `Basic dXNlcm5hbWU6cGFzc3dvcmQ=`
        },
        data: {
            pin: pin,
            token : token,
        },
    }
    // console.log(config);
    return await axios(config).then((res) => {
        setPayment(res.data)
        console.log(res.data.status_code);
        // window.location.href = res.data.acc_link_success_rdr_url
        // console.log(res.data.action_redirect.success_redirect_url);
        if (res.data.status_code === 200) {
          if (res.data.action_redirect.success_redirect_url) {
            window.location.href = `/payment-success?redirect=${res.data.action_redirect.success_redirect_url}`
          } else if (res.data.action_redirect.success_redirect_url === ""){
            window.location.href = `/payment-success`
          }
        } else {
          if (res.data.action_redirect.fail_redirect_url){
            window.location.href = `/payment-failed?redirect=${res.data.action_redirect.fail_redirect_url}`
          } else if (res.data.action_redirect.fail_redirect_url === ""){
            window.location.href = `/payment-failed`
          }
        }

      }).catch((err) => {
        console.log(err.response);
        // setError(err.response.data.message)
        window.location.href = "/payment-failed"
        // if (err.response.data.error_code === "EXPIRED_OTP") {
        //   // history("/500")
        //   console.log(err.response.data.status_code)
        // }
        // else if (err.response.data.error_code === "ACCOUNT_ALREADY_EXISTS") {
        //   history("/success")
        // }
    })
  }
  const handlePinVal = (val) => {
    setPinValue(val);  
    if (val.length === 6 ) {
      setIsDisable(false)
    }
  }

  const handlePayment = () =>{
    setIsDisable(true)
    if (pinValue.length === 6) {
      setIsDisable(true)
      let md5Pin = md5(pinValue);
      validatePin(md5Pin,token);
    } else {
        setError(true)
        console.log("error");
    }

  }

  return (
    <div className="App-wrapper">
        <div className="content">
            <div className="logo">
              <img src="/DuwitLogo2.png" width={240} height={120} alt="duwit-logo"/>
            </div>
            <div style={{padding:"0px 0px 24px 0px"}}>
              <img src="/lock.png" width={32} height={32} alt="lock-icon"/>
              <h4 style={{paddingTop:"24px"}}>Masukan PIN Anda</h4>
            </div>
            <OtpInput
              numInputs={6}
              inputStyle={{
                width: 36,
                height: 36,
                // borderRadius:"50%",
                // border:"2px solid black",
                borderRightWidth:"0px",
                borderTopWidth:"0px",
                borderLeftWidth:"0px",
                fontSize:"55px",
                // backgroundColor:"#D0D0D0",
                // color:"#00BCE3",
                padding:"0px 0px 9px 0px",
                // marginLeft:"110%",
                // caretColor: "transparent",
              }}
              focusStyle={{
                outline: "black"
              }}
              // placeholder="-"
              // separator={<span>-</span>}
              containerStyle={{gap:`8px`}}
              isInputSecure={true}
              onChange={(val)=>handlePinVal(val)}
              value={pinValue}
              isInputNum={true}
            />
            <button className="btn btn-primary" style={{marginTop: "24px" }} type="button" disabled={isDisable} onClick={handlePayment}>Bayar</button>
        </div>

 
    </div>
  );
}

export default Pin;
